/* Default styling */
.home {
  margin: 0;
  position: relative;
}

.hero {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.hero img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 110%;
  object-fit: cover;
  z-index: 1;
  transform: translateZ(1px);
}

.hero__title {
  color: white;
  z-index: 2;
  text-align: center;
  transform: translateZ(-2px) scale(1.0);
}

.hero__title p {
  margin-top: 0.5rem;
  font-size: calc(0.6rem + 0.75vmin);
}

.hero__title a {
  color: white;
}

.hero::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 100%;
  transform-origin: 0 100%;
  transform: translateZ(8px);
  pointer-events: none;
  background-image: linear-gradient(
      to bottom,
      hsla(0, 0%, 0%, 0) 0%,
      hsla(0, 0%, 0%, 0.013) 8%,
      hsla(0, 0%, 0%, 0.049) 14.8%,
      hsla(0, 0%, 0%, 0.104) 20.8%,
      hsla(0, 0%, 0%, 0.175) 26%,
      hsla(0, 0%, 0%, 0.259) 30.8%,
      hsla(0, 0%, 0%, 0.352) 35.3%,
      hsla(0, 0%, 0%, 0.45) 39.8%,
      hsla(0, 0%, 0%, 0.55) 44.5%,
      hsla(0, 0%, 0%, 0.648) 49.5%,
      hsla(0, 0%, 0%, 0.741) 55.2%,
      hsla(0, 0%, 0%, 0.825) 61.7%,
      hsla(0, 0%, 0%, 0.896) 69.2%,
      hsla(0, 0%, 0%, 0.951) 77.9%,
      hsla(0, 0%, 0%, 0.987) 88.1%,
      hsl(0, 0%, 0%) 100%
  );
  z-index: 3;
}

.hero-section {
  background-size: cover;
  background-position: center;
  color: #000000;
  text-align: center; 
}

/* Adjustments for responsiveness */
.hero-content {
  margin: 0 auto;
  height: 70vh; /* Adjust as needed */
  display: flex;
  align-items: center;
  flex-direction: column;
}

.hero-content h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.hero-content p {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.hero-content a {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.hero-content a:hover {
  background-color: #0056b3;
}


/* Featured products section */
.featured-products {
  text-align: center;
  color: #000000;
}

.featured-products h2 {
  margin: 30px;
}