.spinner-component{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.larger-spinner {
    width: 100px;
    height: 100px;
  }
  