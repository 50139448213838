:root {
  --default-padding: 1.2rem;
}
.hub-navigation {
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-backdrop-filter: blur(var(--default-padding));
  backdrop-filter: blur(var(--default-padding));
}

/* Example custom CSS */
@media (max-width: 768px) {
  .navbar-nav {
    font-size: 20px; /* Increase font size */
  }
}

.hub-navigation.scrolled {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}
