.login-card {
  width: 300px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Optional: Add some padding or margin to space out the elements */
.card-body {
  padding: 20px;
}

/* Center the login card vertically and horizontally */
.vh-80 {
  height: 85vh;
}

/* Optional: Style the button wrapper */
.text-left {
    text-align: left;
  }
  .mt-3 {
    margin-top: 15px; /* Adjust as needed */
  }
  