.not-found-container {
    text-align: center;
    margin-top: 50px;
    min-height: 100vh;
    
  }
  
  .not-found-title {
    font-size: 36px;
    color: #ff0000; /* Red color for emphasis */
  }
  
  .not-found-message {
    font-size: 18px;
    color: #666; /* Dark gray color for message */
  }
  