/* Header Styles */
.navbar-brand {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 2px;
}


/* Footer Styles */
.footer {
  background-color: #333;
  color: #fff;
  padding: 40px 0;
}

.footer h4 {
  color: #fff;
}

.footer p, .footer ul {
  color: #ccc;
}

.footer ul {
  list-style-type: none;
  padding: 0;
}

.footer ul li {
  margin-bottom: 5px;
}

.footer hr {
  border-top: 1px solid #555;
  margin: 20px 0;
}

.footer p {
  margin-bottom: 0;
}