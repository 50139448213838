/* search.css */

.search-bar {
    position: relative;
    width: 100%;
    max-width: 600px; /* Set maximum width */
    margin: 0 auto;
}

.search-bar input[type="text"] {
    width: calc(100% - 50px); /* Adjust input width */
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.search-bar button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 11px 15px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
}

.search-results {
    position: absolute;
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1; /* Ensure it appears above other elements */
  }
  
  .search-results .list-group-item {
    cursor: pointer;
  }
  
  .search-result-item {
    display: flex;
    align-items: center;
  }
  
  .search-result-item img {
    width: 100px;
    height: 135px;
  }
  
  .search-result-content {
    flex: 1;
    padding: 10px;
  }
  
  .search-result-content h5 {
    margin: 0;
    font-size: 18px;
  }
  
  .search-result-content p {
    margin: 0;
    font-size: 14px;
  }

@media screen and (max-width: 768px) {
    .search-bar {
        width: 90%; /* Adjust width for smaller screens */
        max-width: none; /* Remove maximum width */
    }

    .search-bar input[type="text"] {
        width: calc(100% - 70px); /* Adjust input width for smaller screens */
    }

    .search-bar button {
        padding: 11.5px; /* Adjust button padding for smaller screens */
    }
    .search-results {
        width: calc(100% - 20px); /* Adjust width for smaller screens */
        left: 10px; /* Center horizontally */
        right: 10px;
        margin: 0;
      }
    .search-result-item img {
        width: 80px;
        height: 100px;
      }
}
