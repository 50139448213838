.product-page {
    margin: 0 auto;
    padding: 20px;
    min-height: 100vh;
  }
  
  .product-details {
    display: flex;
    justify-content: space-between;
  }
  
  .product-images {
    width: 800px; /* Set a fixed width for the container */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; /* Distribute items evenly */
  }
  
  .product-images img {
    width: calc(50% - 5px); /* Adjust width for two images per row */
    margin-bottom: 10px; /* Add margin between rows */
    border-radius: 5px;
    transition: transform 0.3s ease;
    cursor: zoom-in; /* Change cursor to magnifying glass */
  }
  
  .product-images img:hover {
    transform: scale(1.05);
  }
  
  
  .product-info {
    flex: 1;
    padding-left: 20px;
  }

  .product-brand {
    font-size: 1.7em;
    font-weight: 600;
  }

  .product-text{
    font-size: 1.5em;
    max-width: 500px;
    font-weight: 200;
  }
  
  .product-info p {
    margin-bottom: 10px;
  }
  
  .product-info button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .product-info button:hover {
    background-color: #0056b3;
  }
  
  .product-info a {
    display: inline-block;
    padding: 10px 20px;
    background-color: #28a745;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    margin-top: 70px;
  }
  
  .product-info a:hover {
    background-color: #218838;
  }
  
  .expanded-image-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .expanded-image-overlay img {
    max-width: 80%;
    max-height: 80%;
  }
  
    /* Media Query for smaller screens */
    @media (max-width: 768px) {
      .product-images img {
        width: calc(50% - 10px); /* Adjusted for smaller screens */
      }
      .product-details {
        display: flex;
        flex-direction: column;
      }
      .product-images{
        width: 100%;
      }
    }
    
    /* Media Query for even smaller screens */
    @media (max-width: 576px) {
      .product-images img {
        width: 100%; /* Images take full width on smaller screens */
        margin-bottom: 10px; /* Reduced margin between rows */
      }
      .product-details {
        display: flex;
        flex-direction: column;
      }
      .product-images{
        width: 100%;
      }
    }
    