.about-card {
    background-color: #f8f9fa;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    max-width: 800px;
    min-height: 100vh;
  }
  
  .about-card h2 {
    color: #333;
  }
  
  .about-card h3 {
    color: #555;
  }
  
  .about-card p {
    color: #666;
    line-height: 1.6;
  }
  
  .about-card a {
    color: #007bff;
    text-decoration: none;
  }
  
  .about-card a:hover {
    text-decoration: underline;
  }
  