.dashboard {
  padding: 20px;
}

.dashboard-heading {
  font-size: 24px;
  margin-bottom: 20px;
}

.modal-body textarea {
  height: 100px; /* Adjust the height as needed */
}



.dashboard-form {
  padding: 10px;
  max-width: 800px;
  height: 60vh;
  overflow: auto;
}
