/* Default styling */
.findings {
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
}

.findings-heading {
  margin: 15px 0 0 0;
}

.findings-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Distribute items evenly */
}
.black-pagination .page-link {
  color: #000;
  background-color: #fff;
  margin-top: 50px;
  border-color: #000;
}

.black-pagination .page-item.active .page-link {
  background-color: #000;
  border-color: #000;
  color: #fff;
}

.black-pagination .page-link:hover {
  background-color: #000;
  border-color: #000;
  color: #fff;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .findings-content {
    flex-direction: column;
    align-items: center;
  }
}

.top-tools{
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 20px;
}

.limit-select{
  order: -1;
  position: absolute;
  margin-bottom: 40px;
  margin-left: 20px;
}

@media screen and (max-width: 768px) {
  .top-tools {
    flex-direction: column;
    align-items: flex-start;
  }

  .limit-select {
    position: static; /* Remove absolute positioning */
    margin: 10px 0; /* Adjust margin for smaller screens */
    margin-left: 5px;
  }
}