.contact {
  min-height: 100vh;
}
.contact-card {
    background-color: #f8f9fa;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    max-width: 800px;
  }
  
  .contact-card h2 {
    color: #333;
  }
  
  .contact-card p {
    color: #666;
    line-height: 1.6;
  }
  
  .contact-card form {
    margin-top: 20px;
  }
  
  .contact-card label {
    display: block;
    margin-bottom: 5px;
  }
  
  .contact-card input,
  .contact-card textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .contact-card button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .contact-card button:hover {
    background-color: #0056b3;
  }
  